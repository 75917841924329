.grid {
  width: 16.5% !important;
  text-align: center !important;
}

.mobile-hide {
  display: block;
}

@media screen and (max-width: 768px) {
  .grid {
    width: 100% !important;
  }
  .mobile-hide {
    display: none;
  }
}
